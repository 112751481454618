<template>
    <div>
        <main v-if="appLoaded">
            <div class="wrapper">
                <section class="content">
                    <DsErrorBannerContainer />

                    <RouterView v-if="showRouterView" />
                </section>
            </div>
        </main>

        <DsFocusOnKeyboardOnly />
    </div>
</template>

<script>
import { watch } from 'vue';
import { mapState } from 'vuex';
import { useRoute } from 'vue-router';
import { useErrorBanner } from '@infusionsoft/design-system';
import useCreditCardFieldsContext from './useCreditCardFieldsContext';

function useErrorBannerCloseOnRouteChange() {
    const route = useRoute();
    const errorBanner = useErrorBanner();

    watch(() => route, () => {
        errorBanner.close();
    });
}

export default {
    setup() {
        useCreditCardFieldsContext();
        useErrorBannerCloseOnRouteChange();
    },

    watch: {
        overlaysActive(overlaysActive) {
            if (overlaysActive > 0) {
                document.body.classList.add('overlay-active');
            } else {
                document.body.classList.remove('overlay-active');
            }
        },
    },

    computed: {
        ...mapState({
            appLoaded: ({ global }) => global.appLoaded,
            overlaysActive: ({ global }) => global.overlaysActive,
        }),

        showRouterView() {
            return Boolean(this.appLoaded);
        },
    },
};
</script>

<style lang="scss" scoped>
    main {
        .content {
            height: 100vh;
        }
    }
</style>
